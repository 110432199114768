import React, { useEffect } from 'react';

import './style.css';

function AboutUs() {
    useEffect(() => {
        document.title = "Bitvortexhub.com - About us"
    })
    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            {/*  Page content */}
            <main className="flex-grow">
                {/*  Page sections */}
                <section className="relative from-gray-100 to-white">
                    <div className="mt-24 md:mt-32">

                        <div class="max-w-6xl px-4 mx-auto grid grid-cols-12 ">
                            <div className="col-span-12  md:col-span-6">
                                <img src={require('../../images/b712e79f-3abf-4ccb-9025-40f9d77ee116.png').default} />
                            </div>
                            <div className="md:col-span-1"></div>
                            <div className="margin-auto flex flex-col col-span-12 md:col-span-5 mt-8 my-auto  text-lg max-w-3xl mx-auto">
                                <div className=" flex items-center justify-center  w-full text-3xl max-w-3xl py-4">
                                    About Bitvortexhub
                                </div>
                                <div className="my-2">
                                    Bitvortexhub Is a Crypto trading platform.
                                </div>
                                <div className="my-2">
                                    Bitvortexhub offer a simple and secure way for all the individual traders to access easily for buy, sell and stack the varied digital assets across the market.
                                </div>

                            </div>
                        </div>
                        <hr className="my-16" />
                        <div className=" max-w-6xl mx-auto px-4 sm:px-6">
                            <div className="text-lg pt-2">

                                <div className="py-4 text-2xl max-w-3xl mx-auto ">
                                    Our Vision
                                </div>
                                <div className="py-4 max-w-3xl mx-auto ">
                                    We believe Crypto Economy will be the key of the future, therefore we are here to build a unique,efficient and transparent dynamic system with our passionate engineer. We also promise to give everyone in the world a fair chance to discover opportunities for themselves.
                                </div>
                                <div className="py-4 max-w-3xl mx-auto ">
                                    There are more than 100 million users, over 75,000 association across the world. We understand all the investor would like to maximize their gain, therefore we will charge relatively low charge for every order you make.
                                </div>

                            </div>
                        </div>
                        <div className="h-48"></div>
                    </div>
                </section>
            </main>
        </div>
    )
}


export default AboutUs;