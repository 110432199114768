import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';



import Home from './pages/Home';
import SignUp from './pages/signup/SignUp';
import AboutUs from './pages/aboutus/AboutUs';
import Terms from './pages/terms/Terms';
import Privacy from './pages/privacy/Privacy';
import Header from './partials/header/Header';
import Footer from './partials/footer/Footer';

// install Swiper modules
function App() {

  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  const webAppOnClick = () => {
    window.location.href =
      "https://app.bitvortexhub.com/1";
  }
  const downloadOnClick = () => {
    var ua = navigator.userAgent.toLowerCase();
    let isIOS =
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
    if (isAndroid) {
      window.location.href =
        "https://www.bitvortexhub.com/download/bitvortexhub-android.apk";
    } else if (isIOS) {
      window.location.href = "https://n7l61p.cjddphkman.com/jxv7he";
    } else {
      window.location.href =
        "https://www.bitvortexhub.com/download/bitvortexhub-android.apk";
    }
  }

  return (
    <>
      <Header downloadOnClick={downloadOnClick} webAppOnClick={webAppOnClick} />
      <Switch>
        <Route exact path="/">
          <Home downloadOnClick={downloadOnClick} webAppOnClick={webAppOnClick} />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/signup">
          <SignUp />
        </Route>
        <Route path="*" >
          <Redirect to="/"></Redirect>
        </Route>
      </Switch>
      <Footer />
    </>
  );
}

export default App;
